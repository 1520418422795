import React from 'react';

import sedan from 'images/sedan.png'
import van from 'images/van.png'
import truck from 'images/truck.png'
import styled from 'styled-components';

export enum CAR_IMAGE {
  sedan = 'sedan',
  van = 'van',
  truck = 'truck'
}

const CAR_IMAGE_MAP = {
  sedan: sedan,
  van: van,
  truck: truck
}

interface ICarImage {
  car: CAR_IMAGE | string;
}

const Image = styled.img`
  width: 88px;
`

const CarImage: React.FC<ICarImage> = ({car = CAR_IMAGE.sedan}) => {
  return <Image src={CAR_IMAGE_MAP[(car as CAR_IMAGE)]}/>
}

export default CarImage