enum ORDER_STATUS {
  OTHER = 0,
  QUOTATION = 1,
  PROCESSING = 2,
  REJECTED = 4,
  PENDING = 8,
  ASSIGNED = 16,
  TRANSIT = 32,
  DELIVERED = 64,
  DELAYED = 128,
  FAILED = 512,
  HOLD = 1024,
  RETURNED = 2048,
  CANCELLED = 4096,
  CLEARING = 65536,
}

export default ORDER_STATUS;