import ROUTES from "constants/routes";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
  isAuthorizedSelector,
} from "store/reducers/user";

const ProtectedRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const isAuthorized = useSelector(isAuthorizedSelector);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES.login} />
        );
      }}
    />
  )
}

export default ProtectedRoute;
