import { ControllerSize } from "theme/theme";
import Styled from "./Select.styled";


interface ISelect {
  children: React.ReactNode;
  size?: ControllerSize;
  name?: string;
  defaultValue?: any;
  value?: any;
  onChange?: (...args: any) => any;
}

const Select: React.FC<ISelect> = ({ children, size = ControllerSize.MEDIUM, defaultValue, value, name = '', onChange = () => { } }) => (
  <Styled.Select $size={size} defaultValue={defaultValue} onChange={onChange} name={name} value={value}>
    {children}
  </Styled.Select>
)

export default Select;