import { setToken } from "utils/apiInstance";

const localStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (
    action.type.indexOf('login') !== -1 || action.type.indexOf('logout') !== -1
  ) {
    const userState = store.getState().user;
    localStorage.setItem("user", JSON.stringify(userState));

    setToken(userState.token)
  }

  return result;
};

export default localStorageMiddleware;
