import styled, { css } from "styled-components";

import caretDown from 'images/caretDown.svg'
import { ControllerSize, ControllerType, TypographyType } from "theme/theme";

interface ISelect {
  $size: ControllerSize;
}

const Select = styled.select<ISelect>`
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;

  border: 1px solid transparent;
  width: 100%;
  background: url(${caretDown})
      no-repeat right 0.8em center / 1.4em,
      ${props => props.theme.colors.control[ControllerType.SECONDARY].main};
  color: ${props => props.theme.colors.black};
  background-size: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].hover};
  }

  &:focus {
    background-color: ${props => props.theme.colors.white} !important;
    border-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.black};
    outline: 0;
  }


  ${props => {
    switch (props.$size) {
      case ControllerSize.LARGE:
        return css`
            padding: 14px 40px 14px 12px;
          `
      case ControllerSize.MEDIUM:
        return css`
            padding: 10px 40px 10px 12px;
          `
      case ControllerSize.SMALL:
        return css`
            padding: 8px 40px 8px 12px;
          `
    }
  }};
  }

  ${props => {
    switch (props.$size) {
      case ControllerSize.LARGE:
      case ControllerSize.MEDIUM:
        return css`font-size: ${props.theme.typography[TypographyType.buttonLarge].size};`
      case ControllerSize.SMALL:
        return css`font-size: ${props.theme.typography[TypographyType.buttonMedium].size};`
    }
  }};

  option {
    color: inherit;
  }

  &::-ms-expand {
    display: none;
  }
`

const Styled = {
  Select
}

export default Styled;