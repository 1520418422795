import styled from "styled-components"

import StyledDatepicker from "components/Datepicker/Datepicker.styled"
import { transparentize } from "polished"
import { ControllerType } from "theme/theme"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  align-items: center;
  flex-direction: column;

  background: ${props => props.theme.colors.white};
  box-shadow: 0px 1px 0px #DAE0EB;
  border-radius: 4px;

  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`
const OrderInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
`


const ExpandToggle = styled(StyledDatepicker.Day)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${props => transparentize(0.9, props.theme.colors.gray)};
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:hover {
    background-color: ${props => transparentize(0.8, props.theme.colors.gray)};
  }

  & * {
    fill: ${props => props.theme.colors.gray};
  }
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OrderData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DeliveryInformation = styled.div`
`

const DeliveryData = styled.div`

`

const DeliveryArrow = styled(StyledDatepicker.Day)`
  border-radius: 20px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.control[ControllerType.SECONDARY].focus};
  transition: background-color .2s ease-in;
  margin: 0 36px;

  & * {
    fill: ${props => props.theme.colors.control[ControllerType.SECONDARY].focus};
  }
`

const ControlLinks = styled.div`
  & > * {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Package = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid ${props => props.theme.colors.control[ControllerType.SECONDARY].hover};

  &:last-child {
    border-bottom: 0;
  }
`

const PackageCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

const Styled = {
  Container,
  OrderInformation,
  Content,
  ExpandToggle,
  Top,
  OrderData,
  DeliveryInformation,
  DeliveryData,
  DeliveryArrow,
  ControlLinks,
  PackageContainer,
  Package,
  PackageCheckbox
}

export default Styled