import { Row, Col } from 'react-grid-system';

import { ControllerSize, ControllerType, TypographyType } from "theme/theme";
import { Checkbox, Datepicker, Input, Label, Select, Spacer, Typography } from "components";

import Styled from "./RouteCard.styled";

interface IRouteCard {
  routeNumber: number;
  numberOfPackages: number;
  volume: number;
  vehicleType: string;
}

const RouteCard: React.FC<IRouteCard> = ({
  routeNumber,
  numberOfPackages,
  volume,
  vehicleType,
}) => (
  <Styled.Container>
    <Typography type={TypographyType.titleLarge}>Route {routeNumber}</Typography>
    <Spacer height={10} />
    <Row>
      <Col sm={4}>
        <Label>Number of packages</Label>
      </Col>
      <Col sm={8}>
        <Typography type={TypographyType.bodyMedium}>{numberOfPackages}</Typography>
      </Col>
    </Row>
    <Spacer height={10} />
    <Row>
      <Col sm={4}>
        <Label>Total volume</Label>
      </Col>
      <Col sm={8}>
        <Typography type={TypographyType.bodyMedium}>{volume} m3</Typography>
      </Col>
    </Row>
    <Spacer height={10} />
    <Row>
      <Col sm={4}>
        <Label>Suggested vehicle type</Label>
      </Col>
      <Col sm={8}>
        <Typography type={TypographyType.bodyMedium}>{vehicleType}</Typography>
      </Col>
    </Row>
    <Spacer height={10} />
    <Row align="end">
      <Col sm={3}>
        <Label>Route pay ($)</Label>
        <Spacer height={4} />
        <Input name="pickUpAddress" type="text" size={ControllerSize.SMALL}/>
      </Col>
      <Col sm={3}>
        <Label>Driver</Label>
        <Spacer height={4} />
        <Select size={ControllerSize.SMALL}>
          <option>Steven King</option>
        </Select>
      </Col>
      <Col sm={3}>
        <Label>Schedule</Label>
        <Spacer height={4} />
        <Datepicker name="dropOffDateTo" />
      </Col>
      <Col sm={3}>
        <Checkbox name="selectAllPackages" label="Keep hidden" />
      </Col>
    </Row>
  </Styled.Container>
)

export default RouteCard