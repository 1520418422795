import { ControllerSize } from "theme/theme";
import Styled from "./Input.styled";

type InputType = "text" | "password"

interface IInput {
  type: InputType;
  label?: string;
  placeholder?: string;
  size?: ControllerSize;
  block?: boolean;
  error?: string;
  value?: string;
  name: string;
  onChange?: (...args: any) => any;
  onKeyDown?: (...args: any) => any;
}

const Input: React.FC<IInput> = ({
  onChange = () => { },
  onKeyDown = () => { },
  error = "",
  name,
  type,
  label,
  value = "",
  placeholder = " ",
  size = ControllerSize.MEDIUM,
  block = false
}) => (
  <Styled.Input size={size} block={block} error={!!error}>
    <input type={type} onChange={onChange} name={name} id={name} defaultValue={value} placeholder={placeholder} onKeyDown={onKeyDown} />
    <label>{label}</label>
  </Styled.Input>
)

export default Input