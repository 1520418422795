import { chunk } from "lodash";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import moment from "moment";
import { useDetectClickOutside } from 'react-detect-click-outside';

import { Button, Spacer } from "components";
import Typography from "components/Typography/Typography";
import { ControllerSize, ControllerType, TypographyType } from "theme/theme";
import calendarIcon from 'images/calendar.svg';

import Styled from "./Datepicker.styled";
import { useComponentVisible } from "hooks";

interface IDate {
  date: Date;
  outOfMonth: boolean;
}

const getMonthNames = () => {
  const objDate = new Date();
  objDate.setDate(1);
  const locale = "en-us";

  return new Array(12).fill(0).map((d, i) => {
    objDate.setMonth(i);

    return objDate.toLocaleString(locale, { month: "long" });
  });
};

const monthsNames = getMonthNames();

const getDates = (month: number, year: number): IDate[][] => {
  const getDaysInMonth = (month: number, year: number) => {
    return new Array(new Date(year, month + 1, 0).getDate())
      .fill(0)
      .map((v, i) => {
        const date = new Date(year, month, i + 1);

        return date;
      });
  };

  const daysInMonth = getDaysInMonth(month, year);

  let daysToPrepend: Date[] = [];
  const firstDateOfMonth = new Date(daysInMonth[0]);

  const daysToMonday = firstDateOfMonth.getDay() - 1;

  if (daysToMonday > 0) {
    daysToPrepend = new Array(daysToMonday)
      .fill(0)
      .map(
        (date, index) =>
          new Date(firstDateOfMonth.setDate(firstDateOfMonth.getDate() - 1))
      );
  }

  let daysToAppend: Date[] = [];
  const lastDateOfMonth = new Date(daysInMonth[daysInMonth.length - 1]);

  const daysToSunday = 7 - lastDateOfMonth.getDay();

  if (daysToSunday > 0) {
    daysToAppend = new Array(daysToSunday)
      .fill(0)
      .map(
        (date, index) =>
          new Date(lastDateOfMonth.setDate(lastDateOfMonth.getDate() + 1))
      );
  }

  const mapDates = (dates: Date[], outOfMonth = false) => {
    return dates.map((date) => ({
      date,
      outOfMonth,
    }));
  };

  const monthWithExtraDays = [
    ...mapDates(daysToPrepend, true),
    ...mapDates(daysInMonth),
    ...mapDates(daysToAppend, true),
  ];

  const chunkedDays = chunk(monthWithExtraDays, 7).splice(0, 5);

  return chunkedDays;
};

const compareDates = (aDate: Date, bDate: Date): boolean => {
  return (
    aDate.getDate() === bDate.getDate() &&
    aDate.getMonth() === bDate.getMonth() &&
    aDate.getFullYear() === bDate.getFullYear()
  );
};

const getFormattedDate = (date: Date) => {
  return ('0' + date.getDate()).slice(-2) + '.'
    + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
    + date.getFullYear();
}

const currentYear = new Date().getFullYear();

interface IDatepicker {
  onChange?: (...args: any) => any;
  name?: string;
  value?: string;
}

const Datepicker: React.FC<IDatepicker> = ({
  onChange = () => { },
  name = '',
  value
}) => {
  const initialDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const [days, setDays] = useState<IDate[][]>(
    getDates(selectedDate.getMonth(), selectedDate.getFullYear())
  );
  const [showMonth, setShowMonth] = useState<boolean>(false);
  const [showYear, setShowYear] = useState<boolean>(false);
  const [showDatepicker, setShowDatepicker] = useState<boolean>(false);
  const [showDates, setshowDates] = useState<boolean>(false);
  const [datePristine, setDatePristine] = useState<boolean>(true);
  const ref = useComponentVisible(() => {
    setShowMonth(false);
    setshowDates(false);
    setShowYear(false);
    setShowDatepicker(false);
  });

  // const ref = useDetectClickOutside({ onTriggered: closeDropdown });


  const theme = useTheme();

  useEffect(() => {
    if (value === '') {
      setDatePristine(true)
      // setSelectedDate(initialDate)
    }
  }, [initialDate, value])

  const handleDateSelect = (newDate: Date) => () => {
    setSelectedDate(newDate);

    onChange({
      target: {
        name,
        value: moment(newDate).format('YYYY-MM-DD')
      }
    })
  };

  const handleDaySelect = (newDate: Date) => () => {
    setShowDatepicker(false);
    handleDateSelect(newDate)();
    setDatePristine(false);
  }

  useEffect(() => {
    setDays(getDates(selectedDate.getMonth(), selectedDate.getFullYear()));
  }, [selectedDate]);

  const handleShowMonth = () => {
    setShowYear(false)
    setShowMonth(true)
    setshowDates(false);
  }

  const handleShowYear = () => {
    setShowYear(true)
    setShowMonth(false)
    setshowDates(false);
  }

  const handleMonthSelect = (month: number) => () => {
    const newDate = new Date(selectedDate);

    newDate.setMonth(month);

    handleDateSelect(newDate)()

    setShowMonth(false)
    setshowDates(true);
  }

  const handleYearSelect = (year: number) => () => {
    const newDate = new Date(selectedDate);

    newDate.setFullYear(year);

    handleDateSelect(newDate)()

    setShowYear(false)
    setShowMonth(true)
  }

  return (
    <Styled.Datepicker ref={ref}>
      <Styled.Input onClick={() => {setShowDatepicker(true); setshowDates(true);}}>
        <Typography type={TypographyType.bodySmall} color={theme.colors.gray}>
          {datePristine ? '__.__.____' : getFormattedDate(selectedDate)}
        </Typography>
        <Spacer width={20} />
        <img src={calendarIcon} alt="calendar" />
      </Styled.Input>
      {showDatepicker && <Styled.Container visible={showDatepicker}>
        <Styled.Control>
          <Button type={ControllerType.GHOST} size={ControllerSize.SMALL} onClick={handleShowMonth}>
            {selectedDate.toLocaleString("default", { month: "long" })}
          </Button>
          <Button type={ControllerType.GHOST} size={ControllerSize.SMALL} onClick={handleShowYear}>
            {selectedDate.getFullYear()}
          </Button>
        </Styled.Control>
        <Spacer height={12} />
        {showMonth && <Styled.DateSelectorContainer>
          {monthsNames.map((monthName, monthNameIndex) => (
            <Styled.DateSelectorItem key={monthNameIndex} onClick={handleMonthSelect(monthNameIndex)}>
              <Typography type={TypographyType.bodySmall} color={theme.colors.gray}>
                {monthNameIndex + 1 < 10 ? `0${monthNameIndex + 1}` : monthNameIndex + 1}
              </Typography>
              <Spacer width={6} />
              <Typography type={TypographyType.bodySmall}>{monthName}</Typography>
            </Styled.DateSelectorItem>
          ))}
        </Styled.DateSelectorContainer>}
        {showYear && <Styled.DateSelectorContainer>
          {Array(12).fill(currentYear - 2).map((year, yearIndex) => (
            <Styled.DateSelectorItem key={yearIndex} onClick={handleYearSelect(year + yearIndex)}>
              <Typography type={TypographyType.bodySmall}>{year + yearIndex}</Typography>
            </Styled.DateSelectorItem>
          ))}
        </Styled.DateSelectorContainer>}

        {
          showDates && <>
            <Styled.Row>
              <Styled.Day>Mon</Styled.Day>
              <Styled.Day>Tu</Styled.Day>
              <Styled.Day>We</Styled.Day>
              <Styled.Day>Th</Styled.Day>
              <Styled.Day>Fr</Styled.Day>
              <Styled.Day>Sa</Styled.Day>
              <Styled.Day>Su</Styled.Day>
            </Styled.Row>

            {days.map((chunk, chunkIndex) => (
              <Styled.Row key={chunkIndex}>
                {chunk.map((date, dateIndex) => (
                  <Styled.Col
                    key={dateIndex}
                    outOfMonth={date.outOfMonth}
                    selected={compareDates(selectedDate, date.date)}
                    onClick={handleDaySelect(date.date)}
                  >
                    {date.date.getDate()}
                  </Styled.Col>
                ))}
              </Styled.Row>
            ))}
          </>
        }
      </Styled.Container>}
    </Styled.Datepicker>
  );
};

export default Datepicker;
