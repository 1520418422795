import styled from "styled-components";

const Link = styled.span`
  color: inherit;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Styled = {
  Link,
};

export default Styled;
