import { DefaultTheme } from "styled-components";

export enum ControllerType {
  PRIMARY,
  SECONDARY,
  GHOST,
}

export enum ControllerSize {
  LARGE,
  MEDIUM,
  SMALL,
}
export enum TypographyType {
  h1,
  h2,
  h3,
  h4,
  h5,
  titleLarge,
  titleMedium,
  bodyLarge,
  bodyMedium,
  bodySmall,
  caption,
  buttonLarge,
  buttonMedium,
}

const theme: DefaultTheme = {
  colors: {
    main: "#FDD903",
    white: "#fff",
    black: "#515355",
    gray: "#8D8E8F",
    link: "#336FEE",
    red: "#FF6433",
    pageGray: "#F8F9F9",
    green: "#14AE5C",
    control: {
      [ControllerType.PRIMARY]: {
        main:  "#FDD903",
        hover: "#FDBF00",
        focus: "#FDA600",
      },
      [ControllerType.SECONDARY]: {
        main: "#EFF0F1",
        hover: "#E8E9EA",
        focus: "#E7E7E8",
      },
      [ControllerType.GHOST]: {
        main: "transparent",
        hover: "#EFF0F1",
        focus: "#E8E9EA",
      }
    }
  },
  typography: {
    [TypographyType.h1]: {
      size: "61.04px",
      weight: '500'
    },
    [TypographyType.h2]: {
      size: "48.83px",
      weight: '500'
    },
    [TypographyType.h3]: {
      size: "39.06px",
      weight: '500'
    },
    [TypographyType.h4]: {
      size: "31.25px",
      weight: '500'
    },
    [TypographyType.h5]: {
      size: "20px",
      weight: '500'
    },
    [TypographyType.titleLarge]: {
      size: "16px",
      weight: '500'
    },
    [TypographyType.titleMedium]: {
      size: "14px",
      weight: '500'
    },
    [TypographyType.bodyLarge]: {
      size: "16px",
      weight: '400'
    },
    [TypographyType.bodyMedium]: {
      size: "14px",
      weight: '400'
    },
    [TypographyType.bodySmall]: {
      size: "12px",
      weight: '400'
    },
    [TypographyType.caption]: {
      size: "10px",
      weight: '400'
    },
    [TypographyType.buttonLarge]: {
      size: "14px",
      weight: '400'
    },
    [TypographyType.buttonMedium]: {
      size: "12px",
      weight: '400'
    },
  }
};

export { theme };
