import { useState } from "react";
import { create, get, set } from "lodash";

interface IUseForm<T> {
  initialValues: T;
  onSubmit: (values: T, formikHelpers: any) => void | Promise<any>;
  validationSchema?: any;
}

const useForm = <T extends object>({ initialValues, onSubmit, validationSchema }: IUseForm<T>) => {
  const [values, setValues] = useState(initialValues);

  const initialErrors: any = {};

  const [errors, setErrors] = useState(initialErrors);
  const [isValid, setIsValid] = useState(false);
  const [dirty, setIsDirty] = useState(false);

  const setError = (key: string, value: any) => {
    const _errors = create(errors);

    set(_errors, key, value);

    setErrors({
      ...errors,
      ..._errors,
    });
  };

  const addField = (key: string) => {
    const _values = create(values);

    const _value = get(_values, key);

    const defaultValue = get(initialValues, key);

    set(_values, key, [..._value, ...defaultValue]);

    setValues({
      ...values,
      ..._values,
    });

    // setValues({
    //   ...values,
    //   [key]: [
    //     ...values[key],
    //     defaultValue
    //   ]
    // })
  };

  const removeField = (key: string, index: number) => {
    const _values = create(values);

    const _value = get(_values, key);

    _value.splice(index, 1);

    set(_values, key, _value);

    setValues({
      ...values,
      ..._values,
    });
  };

  const setValue = async (key: string, value: any) => {
    const _values = create(values);
    const validationKey = key.replace(/\[\d+\]/g, "");

    set(_values, key, value);
    setIsDirty(true)

    setValues({
      ...values,
      ..._values,
    });

    if(validationSchema) {
      setIsValid(await validationSchema.isValid(_values));
    }

    try {
      const validationFirstPart = validationKey.split(".");

      if (validationSchema.fields[validationFirstPart[0]]?.type === "array") {
        const validation =
          validationSchema.fields[validationFirstPart[0]]?.innerType.fields[
            validationFirstPart[1]
          ];

        await validation.validate(value);
      } else {
        const schemaValidator = get(validationSchema.fields, validationKey);

        await schemaValidator.validate(value);
      }

      setError(key, "");
    } catch (error: any) {
      setError(key, error.message);
    }
  };

  const handleSubmit = async (e: any) => {
    // e.preventDefault();

    if (isValid) {
      onSubmit(values, {});
    }
  };

  const handleChange = async (event: any) => {
    if (!dirty) {
      setIsDirty(true);
    }

    setValue(event.target.name, event.target.value);
  };

  const setFieldValue = async (field: any, value: any) => {
    setValue(field, value);
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  return {
    handleSubmit,
    values,
    errors: errors as any,
    handleChange,
    isValid,
    setFieldValue,
    dirty,
    resetForm,
    onSubmit: handleSubmit,
    addField,
    removeField,
  };
};

export default useForm;
