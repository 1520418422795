import styled, { css } from 'styled-components'


interface IUserpic {
  img?: string;
  name?: string;
}

const stringToColour = (name: string): string => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}


const Userpic = styled.div<IUserpic>`
  width: 36px;
  height: 36px;
  border-radius: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: ${props => props.theme.colors.white};

  ${(props) => {
    if (props.img) {
      return css`
        background: url(${props.img});
      `
    }

    if (props.name) {
      return css`
        background: ${stringToColour(props.name)};
      `
    }

    return css`background: #FD6D3F`;
  }}
`

const Styled = {
  Userpic
}

export default Styled;