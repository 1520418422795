import styled from "styled-components";
import { ControllerType } from 'theme/theme'

const Radio = styled.div`
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: ${props => props.theme.colors.black};
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid ${props => props.theme.colors.gray};
      border-radius: 100%;
      background: ${props => props.theme.colors.white};
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${props => props.theme.colors.control[ControllerType.PRIMARY].main};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
  }

  [type="radio"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
  }



	span {
    position: absolute;
	}


	span:after {
		position: relative;
    content: '';
    left: 7px;
    z-index: 5;
    bottom: 0px;
    border-radius: 4px;
    width: 4px;
    height: 4px;
    display: block;
		background: ${props => props.theme.colors.black};
	}

	[type="radio"]:not(:checked) ~ span {
		display: none
	}

	[type="radio"]:checked ~ span {
    display: block;
	}
`;

const Styled = {
	Radio
}

export default Styled;