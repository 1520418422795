import styled, { css } from "styled-components";
import { ControllerType, TypographyType } from "theme/theme";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


interface IPage {
  active: boolean;
  stub?: boolean;
}

const Page = styled.div<IPage>`
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-in;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.typography[TypographyType.bodySmall].size};
  margin-right: 15px;
  padding: 20px;
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].hover};
  }

  ${props => props.active && css`
    background-color: ${props => props.theme.colors.control[ControllerType.PRIMARY].main};
    cursor: default;

    &:hover {
      background-color: ${props => props.theme.colors.control[ControllerType.PRIMARY].main};
    }
  `}

  ${props => props.stub && css`
    background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].main};
    cursor: default;

    &:hover {
      background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].main};
    }
  `}
`;

const Styled = {
  Container,
  Page
};

export default Styled;
