import styled from "styled-components";

interface ICard {
  children: React.ReactNode;
}

export const StyledCard = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 1px 0px #dae0eb;
  height: 90%;
`;

const Card: React.FC<ICard> = ({ children }) => (
  <StyledCard>
    {children}
  </StyledCard>
)

export default Card;