import styled from 'styled-components'
import { ControllerSize, ControllerType, TypographyType } from 'theme/theme'

export interface IBadge {
  children: React.ReactNode;
  type?: ControllerType;
  size?: ControllerSize;
  block?: boolean;
  disabled?: boolean;
  onClick?: (...args: any) => any;
}

const Badge = styled.div<IBadge>`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typography[TypographyType.bodySmall].size};
  display: inline-block;
  border-radius: 4px;
  padding: 0px 4px;
  background-color: ${props => props.theme.colors.green};
`

export default Badge