import styled from "styled-components";

const Container = styled.div`
padding: 16px 20px;


background: ${props => props.theme.colors.white};
box-shadow: 0px 1px 0px #DAE0EB, 0px 1px 0px #DAE0EB;
border-radius: 4px;
`;

const Styled = {
	Container
}

export default Styled;