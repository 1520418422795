import styled from 'styled-components'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.pageGray};
`

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
`

const Styled = {
  Container,
  Content
}

export default Styled