import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import Pages from 'pages';

import { store } from 'store/store';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Pages />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);