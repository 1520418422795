import styled, { css } from 'styled-components'
import { TypographyType } from 'theme/theme';

interface ITypography {
  children: React.ReactNode;
  type?: TypographyType;
  color?: string;
  bold?: boolean;
}

interface IFont {
  type: TypographyType;
  color?: string;
  bold?: boolean;
}

const Font = styled.span<IFont>`
  ${props => css`
    font-size: ${props.theme.typography[props.type].size};
    font-weight: ${props.theme.typography[props.type].weight};
  `}

  ${props => props.color && css`
    color: ${props.color};
  `}
  ${props => props.bold && css`
    font-weight: 500;
  `}
`;

const Typography: React.FC<ITypography> = ({ children, type = TypographyType.bodyMedium, color, bold }) => (
  <Font type={type} color={color} bold={bold}>{children}</Font>
)

export default Typography