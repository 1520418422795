import styled, { css } from 'styled-components'
import { ControllerSize, ControllerType, TypographyType } from 'theme/theme'

interface IButton {
  $type: ControllerType;
  type: string;
  size: ControllerSize;
  block: boolean;
  disabled: boolean;
}


const Button = styled.button<IButton>`
  cursor: pointer;
  color: ${props => props.theme.colors.black};
  display: inline-block;
  border: 0;
  border-radius: 4px;
  transition: background-color .2s ease-in, border .2s ease-in;
  user-select: none;

  ${props => props.block && css`
    width: 100%;
    text-align: center;
  `}

  ${props => {
    switch (props.size) {
      case ControllerSize.LARGE:
        return css`padding: 16px 20px;`
      case ControllerSize.MEDIUM:
        return css`padding: 12px 20px;`
      case ControllerSize.SMALL:
        return css`padding: 8px 20px;`
    }
  }};


  ${props => {
    switch (props.size) {
      case ControllerSize.LARGE:
      case ControllerSize.MEDIUM:
        return css`font-size: ${props.theme.typography[TypographyType.buttonLarge].size};`
      case ControllerSize.SMALL:
        return css`font-size: ${props.theme.typography[TypographyType.buttonMedium].size};`
    }
  }};


  ${props => {
    const colors = props.theme.colors.control[props.$type]


    return css`
      background-color: ${colors.main};

      ${!props.disabled && css`&:hover {
        background-color: ${colors.hover};
      }`}

      &:active {
        background-color: ${colors.focus};
      }
    `
  }};

  ${props => props.disabled && css`
    opacity: 0.7;
    cursor: default;
  `}
`

const Styled = {
  Button,
}

export default Styled