enum ORDERS_SORT_ORDER {
  pickUpAddressUp = 'pickUpAddressUp',
  pickUpAddressDown = 'pickUpAddressDown',
  pickUpPostalUp = 'pickUpPostalUp',
  pickUpPostalDown = 'pickUpPostalDown',
  dropOffAddressUp = 'dropOffAddressUp',
  dropOffAddressDown = 'dropOffAddressDown',
  dropOffPostalUp = 'dropOffPostalUp',
  dropOffPostalDown = 'dropOffPostalDown',
  pickUpTimeUp = 'pickUpTimeUp',
  pickUpTimeDown = 'pickUpTimeDown',
  dropOffTimeUp = 'dropOffTimeUp',
  dropOffTimeDown = 'dropOffTimeDown',
}

export default ORDERS_SORT_ORDER