import styled from "styled-components"
import { TypographyType } from "theme/theme";

interface ILabel {
  children: React.ReactNode;
}

const StyledLabel = styled.span`
  color: ${props => props.theme.colors.black};
  opacity: 0.7;
  font-size: ${props => props.theme.typography[TypographyType.bodySmall].size};
  font-weight: ${props => props.theme.typography[TypographyType.bodySmall].weight};
`

const Label: React.FC<ILabel> = ({ children }) => (
  <StyledLabel>
    {children}
  </StyledLabel>
)

export default Label