import { ControllerSize, ControllerType } from "theme/theme"

import { Button, Checkbox, Datepicker, Input, Modal, Radio, Searchbox, Select, Spacer, Userpic } from "components"

import UserpicImage from 'images/userpic.png'
import Styled from "./Home.styled"

const Home = () => (
  <Styled.Container>
    <Styled.Presenter>
      <Button>test</Button>
      <Button size={ControllerSize.SMALL}>test</Button>
      <Button size={ControllerSize.LARGE}>test</Button>
      <Button type={ControllerType.SECONDARY}>test</Button>
      <Button type={ControllerType.GHOST}>test</Button>
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Input name="asd" type="text" label="E-mail" size={ControllerSize.LARGE} />
      <Input name="asd" type="text" label="E-mail" />
      <Input name="asd" type="text" label="E-mail" size={ControllerSize.SMALL} />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Radio name="test" value="1" />
      <Radio name="test" value="2" />
      <Radio name="test" value="3" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Radio name="test2" value="1" label="First" />
      <Radio name="test2" value="2" label="Another" />
      <Radio name="test2" value="3" label="Else" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Select size={ControllerSize.LARGE} defaultValue="2">
        <option value="0">Pure CSS Select</option>
        <option value="1">No Wrapper</option>
        <option value="2">No JS</option>
        <option value="3">Nice!</option>
      </Select>
      <Select>
        <option value="0">Pure CSS Select</option>
        <option value="1">No Wrapper</option>
        <option value="2">No JS</option>
        <option value="3">Nice!</option>
      </Select>
      <Select size={ControllerSize.SMALL}>
        <option value="0">Pure CSS Select</option>
        <option value="1">No Wrapper</option>
        <option value="2">No JS</option>
        <option value="3">Nice!</option>
      </Select>
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Userpic />
      <Userpic img={UserpicImage} />
      <Userpic name="John Doe" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Checkbox name="test3" />
      <Checkbox name="test4" />
      <Checkbox name="test5" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Checkbox name="test6" label="First" />
      <Checkbox name="test7" label="Another" />
      <Checkbox name="test8" label="Else" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Datepicker />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Searchbox placeholder="Search" />
    </Styled.Presenter>
    <Spacer height={50} />
    <Styled.Presenter>
      <Modal show title="Add Country">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </Modal>
    </Styled.Presenter>
    <Spacer height={50} />
  </Styled.Container>
)

export default Home