import { useLocation } from "react-router-dom"
import { useCallback } from "react"

import { ControllerSize, ControllerType } from "theme/theme"
import { Button, Link, Spacer } from "components"

import ROUTES from "constants/routes"
import Styled from "./Header.styled"

import logo from 'images/logo.svg'
import { useSelector } from "react-redux"
import { isAuthorizedSelector, logout } from "store/reducers/user"
import { useAppDispatch } from "store/store"

const Header = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector)
  const isActiveLink = useCallback(
    (route: string) => {
      return location.pathname.indexOf(route) !== -1;
    },
    [location.pathname]
  );

  const links: any = {
    "Order Management": ROUTES.orderManagement,
    "Routes": ROUTES.notFound,
    "Pigeons": ROUTES.notFound,
    "Senders": ROUTES.notFound,
    "Pay requests": ROUTES.notFound
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Styled.Container>
      <img src={logo} alt="logo" />
      <Styled.Section>
        {Object.keys(links).map(key => (
          <Styled.NavigationLink $active={isActiveLink(links[key])} to={links[key]} key={key}>{key}</Styled.NavigationLink>
        ))}
      </Styled.Section>
      {isAuthorized ? <Styled.Section>
        <Button size={ControllerSize.MEDIUM} type={ControllerType.GHOST} onClick={handleLogout}>Logout</Button>
      </Styled.Section> : <Styled.Section>
        <Button size={ControllerSize.MEDIUM} type={ControllerType.GHOST}>Sign up</Button>
        <Spacer width={16} />
        <Link to={ROUTES.login}>
          <Button size={ControllerSize.MEDIUM} type={ControllerType.PRIMARY}>Log in</Button>
        </Link>
      </Styled.Section>}
    </Styled.Container>
  )
}

export default Header