import { CAR_IMAGE } from "components/CarImage/CarImage"

const volumeToCar = (volume: number): CAR_IMAGE => {
  if(volume < 216000) {
    return CAR_IMAGE.sedan;
  }


  if(volume < 512000) {
    return CAR_IMAGE.van;
  }


  return CAR_IMAGE.truck;
}

export default volumeToCar;