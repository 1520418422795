import { ControllerSize, ControllerType } from 'theme/theme';
import Styled from './Button.styled'

export interface IButton {
  children: React.ReactNode;
  role?: "button" | "submit" | "reset";
  type?: ControllerType;
  size?: ControllerSize;
  block?: boolean;
  disabled?: boolean;
  onClick?: (...args: any) => any;
}

const Button: React.FC<IButton> = ({
  children,
  role = 'button',
  type = ControllerType.PRIMARY,
  size = ControllerSize.MEDIUM,
  block = false,
  onClick = () => {},
  disabled = false,
}) => {
  const handleClick = () => {
    if(!disabled) {
      onClick()
    }
  }

  return (
    <Styled.Button disabled={disabled} $type={type} type={role} size={size} block={block} onClick={handleClick}>
      {children}
    </Styled.Button>
  )
}

export default Button