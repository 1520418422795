import Styled from "./Checkbox.styled";

interface ICheckbox {
  label?: string;
  name: string;
  value?: any;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (...args: any) => any;
}

const Checkbox: React.FC<ICheckbox> = ({ label = '', name, defaultChecked, checked, onChange = () => {}, value}) => (
  <Styled.Checkbox label={label}>
    <input type="checkbox" id={`${name}-${value}`} name={name} {...{defaultChecked, checked}} onChange={onChange} value={value}/>
    <label htmlFor={`${name}-${value}`}>{label && label}</label>
  </Styled.Checkbox>
)

export default Checkbox