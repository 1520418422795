import Styled from "./Searchbox.styled"

import searchIcon from 'images/search.svg'
import closeIcon from 'images/closeRound.svg'
import { Spacer } from "components"
import { useCallback, useEffect, useState } from "react"
import { useComponentVisible } from "hooks"

interface ISearchbox {
  placeholder?: string;
  options?: any[];
  onChange?: (option: any) => any;
  onSelect?: (option: any) => any;
  optionFormatter?: (option: any) => string;
  value?: any;
}

const Searchbox: React.FC<ISearchbox> = ({
  placeholder,
  value,
  options = [],
  onChange = (option: string) => { },
  onSelect = (option: any) => { },
  optionFormatter = (option: string) => option.toString(),
}) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [optionPicked, setOptionPicked] = useState<boolean>(false);
  const innerRef = useComponentVisible(() => {
    setOptionPicked(true);
  });

  const optionsConditions = value !== '' && !optionPicked;

  const handleOptionSelect = (option: any) => () => {
    onSelect(option)
    setSelectedValue(optionFormatter(option))
    setOptionPicked(true)
  }

  const handleInputChange = (e: any) => {
    onChange(e.target.value);
    setSelectedValue(e.target.value);
    setOptionPicked(false)
  }

  const handleClear = (...args: any) => {
    onChange('');
    setSelectedValue('');
    onSelect({})
    setOptionPicked(true)
  }

  useEffect(() => {
    if (!value) {
      handleClear()
    }
  }, [value])

  return <Styled.Container visible={optionsConditions} ref={innerRef}>
    <Styled.Box>
      <img src={searchIcon} alt="search" />
      <Spacer width={8} />
      <Styled.SearchInput placeholder={placeholder} value={selectedValue} onChange={handleInputChange} />
      <Styled.CloseImage visible={selectedValue !== ''} src={closeIcon} alt="close" onClick={handleClear} />
    </Styled.Box>
    {optionsConditions && options.length > 0 && <Styled.Options>
      {options.map((option, index) =>
        <Styled.Option key={index} onClick={handleOptionSelect(option)}>
          {optionFormatter(option)}
        </Styled.Option>
      )}
    </Styled.Options>}
  </Styled.Container>
}

export default Searchbox