import { Row, Col } from 'react-grid-system';
import { useState } from "react";
import { Collapse } from 'react-collapse';
import { useTheme } from "styled-components";
import moment from "moment";

import { Badge, CarImage, Checkbox, HR, Label, Link, Spacer, Typography } from "components";

import { TypographyType } from "theme/theme";

import { ReactComponent as CaretUp } from 'images/caretUp.svg';
import { ReactComponent as CaretDown } from 'images/caretDown.svg';
import arrowRight from 'images/arrowRight.svg';

import volumeToCar from "constants/volumeToCar";

import Styled from "./Order.styled";


function getTimezoneName(date: string) {
  const today = new Date(date);
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'short' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
}

const formatDate = (date: string) => `${moment(date).format('DD MMMM YYYY, h:mm A')} ${getTimezoneName(date)}`;
interface IOrderProps {
  order: IOrder;
  addPackage: (_packages: IPackage[]) => any;
  removePackage: (_packages: IPackage[]) => any;
  selectedPackages: IPackage[];
}

const Order: React.FC<IOrderProps> = ({
  order,
  addPackage,
  removePackage,
  selectedPackages
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const theme = useTheme();

  const handleOrderSelect = (e: any) => {
    if (e.target.checked) {
      addPackage(order.packages)
    } else {
      removePackage(order.packages)
    }
  }

  const handlePackageSelect = (_package: IPackage) => (e: any) => {
    if (!!selectedPackages.find(p => p.id === _package.id)) {
      removePackage([_package])
    } else {
      addPackage([_package])
    }
  }

  const car = volumeToCar(order.packages.reduce((partialSum, a) => partialSum + a.volume, 0))


  return <Styled.Container>
    <Styled.OrderInformation>
      <Styled.Content>
        <Styled.Top>
          <Styled.OrderData>
            <Checkbox name={order.orderId.toString()} onChange={handleOrderSelect} checked={order.packages.every(_p => selectedPackages.find(p => p.id === _p.id))} />
            <Spacer width={12} />
            <Typography type={TypographyType.titleLarge}>
              #{order.orderId}
            </Typography>
            <Spacer width={12} />
            <Badge>{order.status}</Badge>
            {/* <Spacer width={30} />
            <Typography type={TypographyType.titleLarge}>
              ${order.clientPayment || 0}
            </Typography>
            <Spacer width={30} />
            <Typography type={TypographyType.titleLarge}>
              {order.packages.length}pkg
            </Typography> */}
          </Styled.OrderData>
          {/* <CarImage car={car} /> */}
          <Styled.ExpandToggle onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
            {isExpanded ? <CaretUp /> : <CaretDown />}
          </Styled.ExpandToggle>
        </Styled.Top>
        <Spacer height={15} />
        <Styled.DeliveryInformation>
          <Row>
            <Col sm={3}>
              <Label>Post Time</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{formatDate(order.postTime)}</Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Sender</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{order?.sender?.firstName} {order?.sender?.lastName}</Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Pickup Details</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>
                {order?.pickUpDetails?.sender?.firstName} {order?.pickUpDetails?.sender?.lastName} ({order?.pickUpDetails?.sender?.business})<br />
                {order?.pickUpDetails?.pickUpAddress}
                {order?.pickUpDetails?.telephone || order?.pickUpDetails?.mobile}
              </Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Pickup Time</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{formatDate(order.pickUpTime)}</Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Dropoff Details</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>
                {order?.dropOffDetails?.receiver?.firstName} {order?.dropOffDetails?.receiver?.lastName} ({order?.dropOffDetails?.receiver?.business})<br />
                {order?.dropOffDetails?.dropOffAddress}
                {order?.dropOffDetails?.telephone || order?.pickUpDetails?.mobile}
              </Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Dropoff time</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{formatDate(order.dropOffTime)}</Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Part of the route</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{order.partOfRouteIds ? order.partOfRouteIds.join(", ") : 'N/A'}</Typography>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={3}>
              <Label>Total volume</Label>
            </Col>
            <Col sm={9}>
              <Typography type={TypographyType.bodyMedium}>{order.packages.reduce((partialSum, a) => partialSum + a.volume, 0)}</Typography>
            </Col>
          </Row>

          {/* <Styled.DeliveryData>
            <Typography type={TypographyType.titleMedium}>
              {order?.sender?.firstName} {order?.sender?.lastName}
            </Typography>
            <Spacer height={6} />
            <Typography type={TypographyType.bodyLarge}>
              {order?.sender?.telephone}
            </Typography>
            <Spacer height={6} />
            <Typography type={TypographyType.bodyMedium}>
              {order?.sender?.bisiness}
            </Typography>
            <Spacer height={2} />
            <Typography type={TypographyType.bodySmall} color={theme.colors.red}>
              {moment(order.pickUpTime).calendar()}
            </Typography>
          </Styled.DeliveryData>
          <Styled.DeliveryArrow>
            <img src={arrowRight} alt="arrow" />
          </Styled.DeliveryArrow>
          <Styled.DeliveryData>
            <Typography type={TypographyType.titleMedium}>
              {order?.receiver?.firstName} {order?.receiver?.lastName}
            </Typography>
            <Spacer height={6} />
            <Typography type={TypographyType.bodyLarge}>
              {order?.receiver?.telephone}
            </Typography>
            <Spacer height={6} />
            <Typography type={TypographyType.bodyMedium}>
              {order?.receiver?.bisiness}
            </Typography>
            <Spacer height={2} />
            <Typography type={TypographyType.bodySmall} color={theme.colors.red}>
              {moment(order.dropOffTime).calendar()}
            </Typography>
          </Styled.DeliveryData> */}
        </Styled.DeliveryInformation>
        {/* <Spacer height={16} />
        <Styled.ControlLinks>
          <Link>
            <Typography color={theme.colors.link}>Assign to</Typography>
          </Link>
          <Link>
            <Typography color={theme.colors.link}>Update information</Typography>
          </Link>
          <Link>
            <Typography color={theme.colors.link}>Move to archive</Typography>
          </Link>
        </Styled.ControlLinks> */}
      </Styled.Content>
    </Styled.OrderInformation>
    <Collapse isOpened={isExpanded}>
    <Spacer height={20} />

      <Styled.PackageContainer>
        <Row>
          <Col sm={3}><Typography type={TypographyType.bodyMedium} bold>Package ID</Typography></Col>
          <Col sm={3}><Typography type={TypographyType.bodyMedium} bold>Driver Name</Typography></Col>
          <Col sm={2}><Typography type={TypographyType.bodyMedium} bold>Volume</Typography></Col>
          <Col sm={2}><Typography type={TypographyType.bodyMedium} bold>Status</Typography></Col>
          <Col sm={2}><Typography type={TypographyType.bodyMedium} bold>Manifest</Typography></Col>
        </Row>
        <Spacer height={5} />
        <HR/>
        <Spacer height={5} />
        {order.packages.map(_package =>
            <Row key={order.orderId.toString() + '///' + _package.id.toString()}>
              <Col sm={3}>
                <Row>
                <Col sm={1}>
                <Checkbox name={order.orderId.toString() + _package.id.toString()} checked={!!selectedPackages.find(p => p.id === _package.id)} onChange={handlePackageSelect(_package)} />
                </Col>
                <Col>
                <Typography type={TypographyType.bodyMedium}>{_package.id}</Typography>
                </Col>
                </Row>
              </Col>
              <Col sm={3}><Typography type={TypographyType.bodyMedium}>{_package?.assignedPigeon?.firstName} {_package?.assignedPigeon?.lastName}</Typography></Col>
              <Col sm={2}><Typography type={TypographyType.bodyMedium}>{_package.volume}</Typography></Col>
              <Col sm={2}><Typography type={TypographyType.bodyMedium}>{_package.status}</Typography></Col>
              <Col sm={2}><Typography type={TypographyType.bodyMedium}>Manifest</Typography></Col>
            </Row>

        )}
      </Styled.PackageContainer>
    </Collapse>

  </Styled.Container>
}

export default Order;