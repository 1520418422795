import { Button, Input, Link, Spacer, Typography } from "components"
import useForm from "hooks/useForm"
import { isAuthorizedSelector, login } from "store/reducers/user"
import { useAppDispatch } from "store/store"
import { useTheme } from "styled-components"
import { TypographyType } from "theme/theme"
import Styled from "./Login.styled"
import * as Yup from "yup";
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import ROUTES from "constants/routes"
import { useSelector } from "react-redux"

const Login = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useAppDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector)

  const formik = useForm<ILoginRequest>({
    initialValues: {
      username: "",
      password: ""
    },
    onSubmit: async (values) => {
      await dispatch(
        login({
          username: values.username,
          password: values.password
        })
      );
    },
    validationSchema: Yup.object({
      username: Yup.string().required("This field is required"),
      password: Yup.string().required("This field is required"),
    }),
  });

  useEffect(() => {
    if(isAuthorized) {
      history.push(ROUTES.orderManagement)
    }
  }, [history, isAuthorized])

  return (
    <Styled.Container>
      <Typography type={TypographyType.h5}>Login into PigeonShip US</Typography>
      <Spacer height={32} />
      <form onSubmit={formik.handleSubmit}>
        <Input
          block
          label="Login"
          type="text"
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
          error={formik.errors.username}
        />
        <Spacer height={16} />
        <Input
          block
          label="Password"
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />
        <Spacer height={32} />
        <Button
          block
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.dirty}
        >Sign in</Button>
      </form>
      <Spacer height={32} />
      <Styled.BottomLinks>
        <Link>
          <Typography color={theme.colors.link} type={TypographyType.bodyLarge}>
            Login to PigeonShip Canada
          </Typography>
        </Link>
        <Link>
          <Typography color={theme.colors.link} type={TypographyType.bodyLarge}>
            Forgot password?
          </Typography>
        </Link>
      </Styled.BottomLinks>
    </Styled.Container>
  )
}

export default Login