import styled, { css } from "styled-components";
import { ControllerType, TypographyType } from "theme/theme";

interface IContainer {
  visible: boolean;
}

const Container = styled.div<IContainer>`
  background: ${props => props.theme.colors.white};
  transition: all .2s ease-in;
  position: relative;

  ${props => props.visible && css`
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
    z-index: 100;
  `}
`


const Box = styled.div`
  padding: 12px 16px 12px 12px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].main};
  border-radius: 16px;
  border: 1px solid ${(props) =>
    props.theme.colors.white};
  transition: all .2s ease-in;

  &:hover, &:hover input {
    background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].hover};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
  }
`
const SearchInput = styled.input`
  outline: 0;
  background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].main};
  border: 0;
  font-size: ${(props) => props.theme.typography[TypographyType.bodySmall].size};
  transition: all .2s ease-in;
  flex: 1;

  &:focus {
    background-color: ${(props) => props.theme.colors.white} !important;
  }
`;

const Options = styled.div`
  position: absolute;
  background: white;
  width: 100%;

  background: ${props => props.theme.colors.white};
  transition: all .2s ease-in;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
`;

const Option = styled.div`
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
  transition: all .2s ease-in;

  &:hover {
    background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].main};
  }
`;

interface ICloseImage {
  visible: boolean
}

const CloseImage = styled.img<ICloseImage>`
  cursor: default;
  opacity: 0;
  transition: all .2s ease-in;

  ${props => props.visible && css`
    cursor: pointer;
    opacity: 1;
  `}
`

const Styled = {
  Container,
  Box,
  SearchInput,
  Options,
  Option,
  CloseImage
}

export default Styled;