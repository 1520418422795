import apiInstance from "utils/apiInstance";
import API_ROUTES from "constants/apiRoutes";
import queryString from 'query-string';
import ASSIGNED_STATUS from "constants/assignedStatuses";

export const loginRequest = async (loginPayload: ILoginRequest) => {
  const response = await apiInstance.post<ILoginResponse>(API_ROUTES.login, loginPayload);

  return response.data;
};

export const getSendersRequest = async ({limit = 10, ...sendersQuery}: IGetSendersQuery) => {
  const response = await apiInstance.get<IGetSendersResponse>(`${API_ROUTES.senders}?search=${sendersQuery.search}&limit=${limit}`);

  return response.data;
};

export const getOrdersRequest = async ({ assigned = ASSIGNED_STATUS.ALL, ...ordersQuery}:  IGetOrdersQuery, {
  limit = 50,
  page = 1
}: IPagination) => {
  const getOrdersQueryString = queryString.stringify({
    assigned,
    limit,
    page,
    ...ordersQuery,
  }, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
    encode: false,
  });

  const response = await apiInstance.get<IGetOrdersResponse>(`${API_ROUTES.orders}?${getOrdersQueryString}`);

  return response.data;
};

export const createRouteRequest = async ({
  packageIds,
  maxRoutes = 1
}: ICreateRouteRequest) => {
  const response = await apiInstance.post<any>(API_ROUTES.createRoute, {
    packageIds,
    maxRoutes
  });

  return response.data;
};

// export const patchUserRequest = async (user: IPatchUser) => {
//   const response: AxiosResponse = await apiInstance.patch(
//     `${API_ROUTES.user}/${user.user_id}`,
//     {
//       user,
//     }
//   );

//   return response.data as IPatchUserResponse;
// };

// export const createUserRequest = async (user: ICreateUser) => {
//   const response: AxiosResponse = await apiInstance.post(`${API_ROUTES.user}`, {
//     ...user,
//     _url: `${window.location.origin}${ROUTES.registration}/`,
//   });

//   return response.data as IUser;
// };
