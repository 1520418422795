import styled, { css } from "styled-components";


interface IBackdrop {
  show: boolean
}

const Backdrop = styled.div<IBackdrop>`
  background-color: rgb(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: none;
  position: fixed;

  ${props => props.show && css`
    display: flex;
  `}
`

const Modal = styled.div`

max-height: 90vh;
    display: flex;
    flex-direction: column;
  min-width: 400px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

const Top = styled.div`
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 8px 20px rgba(224, 228, 235, 0.6);

`

const Content = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.colors.pageGray};
`

const Footer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  display: none;
`

const CloseImage = styled.img`
  cursor: pointer;
`


const Styled = {
  Backdrop,
  Modal,
  Top,
  Content,
  Footer,
  CloseImage,
}

export default Styled;