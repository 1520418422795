import styled, { css } from "styled-components";
import { ControllerType } from "theme/theme";

interface ICheckbox {
  label: string
}

const Checkbox = styled.div<ICheckbox>`
  [type="checkbox"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  [type="checkbox"] + label {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 22px;
    color: ${props => props.theme.colors.black};
  }

  [type="checkbox"] + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    ${props => props.label && css`
      margin-right: 6px;
    `}
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.colors.control[ControllerType.PRIMARY].main};
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.control[ControllerType.PRIMARY].main};
    border-radius: 2px;
    box-shadow: none;

    transition: all 0.2s ease-in;
  }

  [type="checkbox"]:checked + label::after {
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7' fill='none'%3E%3Cpath d='M9 1L4 6L1 3' stroke='%23515355' stroke-linecap='square'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    left: 0px;
    top: 3px;
    text-align: center;
    background-color: transparent;
    font-size: 10px;
    height: 16px;
    width: 16px;
  }

  [type="checkbox"]:not(:checked) + label:before {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.black};
  }
`;

const Styled = {
  Checkbox,
};

export default Styled;
