import { Link as RouterLink, useHistory } from "react-router-dom";

import Styled from "./Link.styled";

interface LinkProps {
  children: any;
  className?: string;
  back?: boolean;
  to?: string;
}

const Link: React.FC<LinkProps> = ({
  children,
  className = "",
  to = "",
  back = false,
}) => {
  const history = useHistory();

  if (!back) {
    return (
      <Styled.Link className={className}>
        <RouterLink to={to}>{children}</RouterLink>
      </Styled.Link>
    );
  }

  return (
    <Styled.Link className={className} onClick={() => history.goBack()}>
      {children}
    </Styled.Link>
  );
};

export default Link;
