import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { loginRequest } from "api";
import { RootState } from "store/store";
import jwtDecode from "jwt-decode";
import { setToken } from "utils/apiInstance";

interface IUserSliceState {
  user: IUser;
  loading: boolean;
  hasErrors: boolean;
  token: string,
}

const emtpyUser = {
  email: "",
  username: "",
  user_id: 0
};

const localStorageState = JSON.parse(
  localStorage.getItem("user") || "null"
);

export const emptyState: IUserSliceState = {
  loading: false,
  hasErrors: false,
  token: '',
  user: emtpyUser,
};

export const initialState: IUserSliceState = localStorageState || emptyState;

setToken(initialState.token)

export const login = createAsyncThunk(
  "user/login",
  async (loginPayload: ILoginRequest, thunkApi) => {
    const response: ILoginResponse = await loginRequest(loginPayload);

    return response;
  }
);

const loginPending = (state: IUserSliceState) => ({
  ...state,
  loading: true,
  hasErrors: false,
});

const loginFulfilled = (
  state: IUserSliceState,
  action: PayloadAction<ILoginResponse>
) => {
  const {
    user_id,
    email,
    username,
  } = jwtDecode<ILoginToken>(action.payload.token);

  setToken(action.payload.token)

  return {
    ...state,
    user: {
      user_id,
      email,
      username,
    },
    token: action.payload.token,
    loading: false,
    hasErrors: false,
  };
};

const loginRejected = (state: IUserSliceState) => ({
  ...state,
  user: emtpyUser,
  loading: false,
  hasErrors: true,
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.pending, loginPending);
    builder.addCase(login.fulfilled, loginFulfilled);
    builder.addCase(login.rejected, loginRejected);
  },
  reducers: {
    logout: (state) => {

      state.token = emptyState.token
    },
  }
});

export const { logout } = userSlice.actions


export const userSliceSelector = (state: RootState): IUserSliceState =>
  state.user;

export const selectUser = createSelector(
  userSliceSelector,
  (userState: IUserSliceState) => userState.user
);
export const isAuthorizedSelector = createSelector(
  userSliceSelector,
  (userState: IUserSliceState) => !!userState.token
);

export default userSlice.reducer;
