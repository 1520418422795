import Styled from "./Modal.styled"

import closeIcon from 'images/closePlain.svg'
import Typography from "components/Typography/Typography";
import { ControllerSize, ControllerType, TypographyType } from "theme/theme";
import { Button, Spacer } from "components";

interface IModal {
  children: React.ReactNode;
  title: string;
  onOk?: () => any;
  onCancel?: () => any;
  show: boolean;
}

const Modal: React.FC<IModal> = ({
  children,
  title,
  show,
  onOk = () => {},
  onCancel = () => {}
}) => {

  const close = () => {
    onCancel()
  }

  return <Styled.Backdrop show={show}>
    <Styled.Modal >
    <Styled.Top>
      <Typography type={TypographyType.titleLarge}>
        {title}
      </Typography>
      <Styled.CloseImage src={closeIcon} onClick={close}/>
    </Styled.Top>
    <Styled.Content>
      {children}
    </Styled.Content>
    <Styled.Footer>
      <Button size={ControllerSize.MEDIUM} type={ControllerType.GHOST} onClick={onCancel}>Cancel</Button>
      <Spacer width={12}/>
      <Button size={ControllerSize.MEDIUM} type={ControllerType.PRIMARY} onClick={onOk}>Apply</Button>
    </Styled.Footer>
  </Styled.Modal>
  </Styled.Backdrop>
}

export default Modal