import { Redirect, Route, Switch } from "react-router-dom";

import { Layout, ProtectedRoute } from 'components';

import Home from './Home/Home';
import OrderManagement from './OrderManagement/OrderManagement';
import Login from './Login/Login';

import ROUTES from "constants/routes";



const Pages = () => (
  <Layout>
    <Switch>
      <Route path={ROUTES.login} exact component={Login} />

      <Route path={ROUTES.home} exact component={() => <Redirect to={ROUTES.login}/>} />
      <Route path={ROUTES.components} exact component={Home} />

      <ProtectedRoute path={ROUTES.orderManagement} exact component={OrderManagement} />
    </Switch>
  </Layout>
)

export default Pages