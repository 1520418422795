import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components'



const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 80px;

  background: ${props => props.theme.colors.white};
  box-shadow: 0px 8px 20px rgba(42, 50, 57, 0.08);
`


const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

interface INavigationLink {
  $active?: boolean;
}

const NavigationLink = styled(Link)<INavigationLink>`
  text-decoration: none;
  margin-right: 52px;
  cursor: pointer;
  color: ${props => props.theme.colors.gray};
  align-self: stretch;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  ${props => props.$active && css`
    color: ${props.theme.colors.black};
    border-bottom: 2px solid ${props.theme.colors.black}
  `}
`


const Styled = {
  Container,
  NavigationLink,
  Section,
}

export default Styled;