import styled, { css } from "styled-components";
import { ControllerType, TypographyType } from "theme/theme";

interface IContainer {
  visible: boolean;
}

const Container = styled.div<IContainer>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 12px;
  background: #ffffff;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.visible && css`
    z-index: 100;
  `}
`;

const Row = styled.div`
  display: flex;
`;

interface ICol {
  outOfMonth?: boolean;
  selected?: boolean;
}


const Day = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-size: ${(props) => props.theme.typography[TypographyType.caption].size};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const Col = styled(Day)<ICol>`
  font-size: ${(props) =>
    props.theme.typography[TypographyType.bodySmall].size};
  border-radius: 16px;
  cursor: pointer;
  color: ${props => props.theme.colors.black};
  transition: background-color .2s ease-in;

  ${(props) =>
    props.outOfMonth &&
    css`
      color: ${props.theme.colors.gray};
    `}
  ${(props) =>
    props.selected &&
    css`
      background-color: ${props.theme.colors.control[ControllerType.PRIMARY]
        .main} !important;
    `}

    &:hover {
    background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].hover};
  }
`;


const Control = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;


const DateSelectorContainer = styled.div`
  height: 272px;
  overflow: scroll;
  width: 100%;
`;

const DateSelectorItem = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  transition: background-color .2s ease-in;

  &:hover {
    background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].hover};
  }
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: ${(props) =>
    props.theme.colors.control[ControllerType.SECONDARY].hover};
  border-radius: 4px;
  border: 1px solid ${(props) =>
    props.theme.colors.white};
  border-radius: 4px;
  transition: all .2s ease-in;
  user-select: none;
  cursor: pointer;

  &:hover, &:focus {
    background-color: ${(props) =>
    props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.black};
  }
`

const Datepicker = styled.div`
  position: relative;
`

const Styled = {
  Container,
  Row,
  Col,
  Day,
  Control,
  DateSelectorContainer,
  DateSelectorItem,
  Input,
  Datepicker
};

export default Styled;
