import Styled from "./Radio.styled";

interface IRadio {
  label?: string;
  name: string;
  value: any;
  defaultChecked?: boolean;
  onChange?: (...args: any) => any;
}

const Radio: React.FC<IRadio> = ({ label, name, value, defaultChecked, onChange = () => {}}) => (
  <Styled.Radio>
    <input type="radio" id={`${name}-${value}`} name={name} value={value} defaultChecked={defaultChecked} onChange={onChange}/>
    <label htmlFor={`${name}-${value}`}>{label && label}</label>
  </Styled.Radio>
)

export default Radio