import Typography from "components/Typography/Typography";
import { TypographyType } from "theme/theme";
import Styled from "./Userpic.styled";

interface IUserpic {
  name?: string;
  img?: string;
}

const Userpic: React.FC<IUserpic> = ({ name, img }) => {
  if (img) {
    return <Styled.Userpic img={img} />
  }

  if (name) {
    const displayName = name.split(' ').map(n => n[0]).join('').toLocaleUpperCase()
    return <Styled.Userpic name={name}>
      <Typography type={TypographyType.titleMedium}>
        {displayName}
      </Typography>
    </Styled.Userpic>
  }

  return <Styled.Userpic name="?">
    <Typography type={TypographyType.titleMedium}>
      ?
    </Typography>
  </Styled.Userpic>
}

export default Userpic