import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";

import { getSendersRequest, getOrdersRequest } from "api";
import { RootState } from "store/store";

import rawOrder from '../../api/order'


interface IOrdersSliceState {
  loading: boolean;
  hasErrors: boolean;
  orders: IOrder[];
  senders: ISender[];
  pagination: IPagination;
  selectedPackages: IPackage[];
  totalOrders: number;
}


export const initialState: IOrdersSliceState = {
  loading: false,
  hasErrors: false,
  orders: [],
  senders: [],
  pagination: {
    limit: 50,
    page: 1,
    totalPages: 0,
  },
  totalOrders: 0,
  selectedPackages: []
};


export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (query: IGetOrdersQuery, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const response: IGetOrdersResponse = await getOrdersRequest(query, state.orders.pagination);

    return response;
  }
);

const fetchOrdersPending = (state: IOrdersSliceState) => ({
  ...state,
  loading: true,
  hasErrors: false,
});

const fetchOrdersFulfilled = (
  state: IOrdersSliceState,
  action: PayloadAction<IGetOrdersResponse>
) => {

  return {
    ...state,
    orders: action.payload.result,
    pagination: {
      ...state.pagination,
      totalPages: action.payload.info.pages
    },
    totalOrders: action.payload.info.totalOrders,
    loading: false,
    errors: false,
  };
};

const fetchOrdersRejected = (state: IOrdersSliceState) => ({
  ...state,
  orders: [],
  loading: false,
  hasErrors: true,
});

export const fetchSenders = createAsyncThunk(
  "orders/fetchSenders",
  async (senderName: string) => {

    const response: IGetSendersResponse = await getSendersRequest({
      search: senderName
    });

    return response;
  }
);


const fetchSendersFulfilled = (
  state: IOrdersSliceState,
  action: PayloadAction<IGetSendersResponse>
) => {

  return {
    ...state,
    senders: action.payload
  };
};

const fetchSendersRejected = (state: IOrdersSliceState) => ({
  ...state,
  senders: [],
});

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    selectPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, fetchOrdersPending);
    builder.addCase(fetchOrders.fulfilled, fetchOrdersFulfilled);
    builder.addCase(fetchOrders.rejected, fetchOrdersRejected);
    builder.addCase(fetchSenders.fulfilled, fetchSendersFulfilled);
    builder.addCase(fetchSenders.rejected, fetchSendersRejected);
  },
});

export const ordersSliceSelector = (state: RootState): IOrdersSliceState =>
  state.orders;

export const selectOrders = createSelector(
  ordersSliceSelector,
  (orderState: IOrdersSliceState) => orderState.orders
);

export const selectIsOrdersLoading = createSelector(
  ordersSliceSelector,
  (orderState: IOrdersSliceState) => orderState.loading
);

export const selectSenders = createSelector(
  ordersSliceSelector,
  (orderState: IOrdersSliceState) => orderState.senders
);

type Return = (state: RootState) => IOrder | undefined;

export const selectOrderSelector = (id: number): Return =>
  createSelector(
    selectOrders,
    (orders) => orders.find(order => order.orderId === id)
  );

export const selectOrderPagination = createSelector(
  ordersSliceSelector,
  (orderState: IOrdersSliceState) => orderState.pagination
);

export const { selectPage } = orderSlice.actions
export default orderSlice.reducer;
