import { StyledCard } from 'components/Card/Card'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  padding-right: 36px;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  padding: 24px;
`

const Sidebar = styled.div`
  min-width: 320px;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 1px 0px #dae0eb;
  min-height: 90vh;
  z-index: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Right = styled(StyledCard)`
  padding: 16px;
  margin-top: 32px;
  width: 372px;
`

const TopBar = styled.div`
`

const Styled = {
  Container,
  Sidebar,
  Content,
  Right,
  TopBar
}

export default Styled;