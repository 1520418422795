import styled, { css } from 'styled-components'
import { ControllerSize, ControllerType, TypographyType } from 'theme/theme'

interface IInput {
  size: ControllerSize;
  block: boolean;
  error: boolean;
}

const Input = styled.div<IInput>`
  position: relative;

  ${props => props.block && css`
    width: 100%;
    display: block;
  `}

  input {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].main};
    border: 1px solid transparent;
    color: ${props => props.theme.colors.black};
    border-radius: 4px;
    display: block;

    transition: border-color .15s ease-in,box-shadow .15s ease-in, padding .15s ease-in, background-color .15s ease-in;
    width: 100%;


    &:hover {
      background-color: ${props => props.theme.colors.control[ControllerType.SECONDARY].hover};
    }

    &:focus {
      background-color: ${props => props.theme.colors.white} !important;
      border-color: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.black};
      outline: 0;
    }

    &:not(:placeholder-shown) {
      /* padding: 14px 12px; */

      background-color: ${props => props.theme.colors.control[ControllerType.GHOST].hover};
    }

    ${props => {
      switch (props.size) {
        case ControllerSize.LARGE:
        case ControllerSize.MEDIUM:
          return css`font-size: ${props.theme.typography[TypographyType.buttonLarge].size};`
        case ControllerSize.SMALL:
          return css`font-size: ${props.theme.typography[TypographyType.buttonMedium].size};`
      }
    }};

    ${props => {
      switch (props.size) {
        case ControllerSize.LARGE:
          return css`
            padding: 20px 12px 10px 12px;
          `
        case ControllerSize.MEDIUM:
          return css`
            padding: 14px 12px 8px 12px;
          `
        case ControllerSize.SMALL:
          return css`
            padding: 8px 12px 8px 12px;
          `
      }
    }};
  }

  label {
    border: 1px solid transparent;
    height: 100%;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in,transform .1s ease-in;
    white-space: nowrap;
    width: 100%;
    color: ${props => props.theme.colors.gray};
    font-size: ${props => props.theme.typography[TypographyType.buttonLarge].size};

    ${props => {
      switch (props.size) {
        case ControllerSize.LARGE:
          return css`
            font-size: ${props.theme.typography[TypographyType.buttonLarge].size};
            padding: 14px 12px 14px 12px;
          `
        case ControllerSize.MEDIUM:
          return css`
            font-size: ${props.theme.typography[TypographyType.buttonLarge].size};
            padding: 12px 12px 10px 12px;
          `
        case ControllerSize.SMALL:
          return css`
            font-size: ${props.theme.typography[TypographyType.buttonMedium].size};
            padding: 6px 12px 6px 12px;
          `
      }
    }};
  }

  input:focus ~ label, input:not(:placeholder-shown)~label{
    opacity: 1;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);


    ${props => {
      switch (props.size) {
        case ControllerSize.LARGE:
        case ControllerSize.MEDIUM:
          return css`font-size: ${props.theme.typography[TypographyType.caption].size};`
        case ControllerSize.SMALL:
          return css`display: none;`
      }
    }};
  }

  input:focus ~ label {
    color: ${props => props.theme.colors.black} !important;
  }

  ${props => props.error && css`
    label, input:focus ~ label {
      color: ${props => props.theme.colors.red} !important;
    }

    input {
      border: 1px solid ${props => props.theme.colors.red} !important;
    }
  `}
`

const Styled = {
  Input
}

export default Styled;