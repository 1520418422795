import Axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { logout } from "store/reducers/user";

import {useDispatch} from 'react-redux'

let token = ''

const getToken = () => {
  return token;
};
export const setToken = (_token: string) => {
  token = _token
};


interface IApiInstance extends AxiosInstance {
  store?: any;
}

const API_URL = process.env.REACT_APP_API_URL;

function apiInstance(): IApiInstance {
  const instance = Axios.create({
    baseURL: API_URL,
  });

  instance.interceptors.request.use(
    async function (options: AxiosRequestConfig) {
      if (getToken()) {
        (options.headers ?? {})["Authorization"] = `Bearer ${getToken()}`;
      }

      (options.headers ?? {})["content-type"] = `application/json`;

      return options;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // access denied (403 Forbidden)
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        alert("Session expired");

        const d = useDispatch()
        d(logout())
      }

      if (error?.response?.status >= 500) {
        console.log("Server down");
        alert("Something went wrong. Please, try again later");
      }

      throw error;
    }
  );

  return instance;
}

export default apiInstance();
