import { Header } from "components";
import Styled from "./Layout.styled";

interface ILayout {
  children: React.ReactNode
}

const Layout: React.FC<ILayout> = ({ children }) => {
  return (
    <Styled.Container>
      <Header />

      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  );
};

export default Layout;
