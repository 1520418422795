import { Row, Col } from 'react-grid-system';
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { Button, Input, Label, Typography, Select, Spacer, HR, Datepicker, Order, CarImage, Searchbox, Card, Radio, Checkbox, Modal, Pagination, RouteCard } from "components"

import { useAppDispatch } from "store/store";
import { fetchOrders, fetchSenders, selectIsOrdersLoading, selectOrderPagination, selectOrders, selectPage, selectSenders } from "store/reducers/orders";
// import useForm from "hooks/useForm";

import { ControllerSize, ControllerType, TypographyType } from "theme/theme"
import { CAR_IMAGE } from "components/CarImage/CarImage";

import Styled from "./OrderManagement.styled"
import { debounce, flatten, uniqBy } from 'lodash';
import volumeToCar from 'constants/volumeToCar';
import { createRouteRequest } from 'api';
import ASSIGNED_STATUS from 'constants/assignedStatuses';
import ORDERS_SORT_ORDER from 'constants/ordersSort';
import ORDER_STATUS from 'constants/orderStatuses';
import { useFormik } from 'formik';

const OrderManagement = () => {
  const dispatch = useAppDispatch();
  const orders = useSelector(selectOrders);
  const isOrdersLoading = useSelector(selectIsOrdersLoading);
  const senders = useSelector(selectSenders);
  const pagination = useSelector(selectOrderPagination);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedPackages, setSelectedPackages] = useState<IPackage[]>([]);
  const allselected = orders.length > 0 ? flatten(orders.map(order => order.packages)).every(_package => selectedPackages.find(p => p.id === _package.id)) : false


  const handlePageSelect = (page: number) => {
    dispatch(selectPage(page))
  }

  const addPackage = (__packages: IPackage[]) => {
    setSelectedPackages([
      ...selectedPackages,
      ...__packages
    ])
  }

  const removePackage = (__packages: IPackage[]) => {
    setSelectedPackages([
      ...selectedPackages.filter(_package => !!!__packages.find(p => p.id === _package.id))
    ])
  }

  const totalDistance = uniqBy(selectedPackages.map(_package => orders.find(order => order.orderId === _package.orderId)), function (e) {
    return e?.orderId;
  }).map(order => order?.gmapDist || order?.straightDistance || 0).reduce((partialSum, a) => partialSum + a, 0) / 1000 || 0;


  const totalVolume = selectedPackages.reduce((partialSum, a) => partialSum + a.volume, 0);

  const totalMiles = Number(totalDistance / 1.60934).toFixed(1);

  const totalHoursForDistance = totalDistance / 60;

  const timeForDistance = `${totalHoursForDistance.toFixed()}h ${Number(Number(totalHoursForDistance % 1).toString().slice(2, 4))}m`;

  const carForVolume = volumeToCar(totalVolume)

  const filterForm = useFormik({
    initialValues: {
      sender: "",
      postedDateFrom: "",
      postedDateTo: "",
      pickupDateFrom: "",
      pickUpDateTo: "",
      dropOffDateFrom: "",
      dropOffDateTo: "",
      pickUpAddress: "",
      postal: "",
      search: "",
      assigned: ASSIGNED_STATUS.ALL,
      sort: ORDERS_SORT_ORDER.pickUpAddressUp,
      status: []
    },
    onSubmit: async (values) => {
      getOrders()
    }
  })

  const getOrders = debounce(async () => {
    dispatch(selectPage(1))

    await dispatch(fetchOrders(filterForm.values))
  },500)

  useEffect(() => {
    dispatch(fetchOrders(filterForm.values))
  }, [dispatch, pagination.page])

  const handleSearchKeyDown = (e: any) => {
    if(e.keyCode === 13) {
      getOrders()
    }
  }

  const handleSenderChange = debounce((e: string) => {
    if(e !== '') {
      dispatch(fetchSenders(e))
    }
  }, 500)

  const searchOrders = useCallback(() => {
    getOrders()
  }, [getOrders])

  useEffect(() => {
    getOrders()
  }, [dispatch, filterForm.values.sort])

  const handleCreateRoute = useCallback(async () => {
      const r = await createRouteRequest({
      packageIds: selectedPackages.map(_p => _p.id)
    }).catch(e => {
      alert(e?.response?.data?.message || e.message)
    })

    alert(r.message)
  }, [selectedPackages])

  const handleMarkAll = (e: any) => {
    if (isOrdersLoading) {
      return;
    }

    if (e.target.checked) {
      addPackage(flatten(orders.map(order => order.packages)))
    } else {
      removePackage(flatten(orders.map(order => order.packages)))
    }
  }

  const hasAssignedPackages = selectedPackages.some(p => p.assigned || p.status !== 'PROCESSING')

  return (
    <Styled.Container>
      <Modal title="Create route" show={isModalOpen} onCancel={() => setIsModalOpen(false)}>
        <Styled.Container>
        <Styled.Sidebar>
          <Row>
            <Col sm={10}>
              <Typography type={TypographyType.titleLarge}>Number of packages selected</Typography>
            </Col>
            <Col sm={2}>
              <Typography type={TypographyType.titleLarge}>{selectedPackages.length}</Typography>
            </Col>
          </Row>
          <Spacer height={20} />

          <Label>Number of routes</Label>
          <Spacer height={4} />
          <Input name="Number of routes" type="text" placeholder="Number of routes" />

          <Spacer height={20} />
          <Checkbox name="Automatically decide number of routes" label="Automatically decide number of routes" />

          <Spacer height={20} />
          <Label>Return options</Label>
          <Spacer height={4} />
          <Radio name="Return options" value={ASSIGNED_STATUS.ALL} label="Same as pickup" defaultChecked />
          <Spacer height={4} />
          <Radio name="Return options" value={ASSIGNED_STATUS.ASSIGNED} label="Same as order" />
          <Spacer height={4} />
          <Radio name="Return options" value={ASSIGNED_STATUS.UNASSIGNED} label="Custom" />

          <Spacer height={20} />
          <Button type={ControllerType.PRIMARY} block >Create routes</Button>

        </Styled.Sidebar>
        <Spacer width={24} />
        <Styled.Content>
          <RouteCard routeNumber={1} numberOfPackages={12} volume={45} vehicleType="sedan" />
          <Spacer height={20} />
          <RouteCard routeNumber={2} numberOfPackages={23} volume={67} vehicleType="sedan" />
          <Spacer height={20} />
          <RouteCard routeNumber={3} numberOfPackages={34} volume={89} vehicleType="truck" />
        </Styled.Content>
        </Styled.Container>
      </Modal>
      <Styled.Sidebar>
        <Typography type={TypographyType.titleLarge}>Filter</Typography>
        <form onSubmit={filterForm.handleSubmit}>

          <Spacer height={24} />
          <Row>
            <Col sm={12}>
              <Label>Sender</Label>
              <Spacer height={4} />
              <Searchbox
                placeholder="Start typing..."
                onChange={handleSenderChange}
                optionFormatter={(option) => option.full_name}
                options={senders}
                value={filterForm.values.sender}
                onSelect={option => filterForm.setFieldValue("sender", option?.id || 0)}
              />
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Label>
                Posted Date Range
              </Label>
            </Col>
          </Row>
          <Spacer height={4} />

          <Row>
            <Col sm={6}>
              <Datepicker name="postedDateFrom" value={filterForm.values.postedDateFrom} onChange={filterForm.handleChange} />
            </Col>
            <Col sm={6}>
              <Datepicker name="postedDateTo" value={filterForm.values.postedDateTo} onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />


          <Row>
            <Col sm={12}>
              <Label>
                Pickup Date Range
              </Label>
            </Col>
          </Row>
          <Spacer height={4} />

          <Row>
            <Col sm={6}>
              <Datepicker name="pickupDateFrom" value={filterForm.values.pickupDateFrom} onChange={filterForm.handleChange} />
            </Col>
            <Col sm={6}>
              <Datepicker name="pickUpDateTo" value={filterForm.values.pickUpDateTo} onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Label>Pickup address</Label>
              <Spacer height={4} />
              <Input name="pickUpAddress" type="text" placeholder="Pickup address" onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />


          <Row>
            <Col sm={12}>
              <Label>
                Dropoff Date Range
              </Label>
            </Col>
          </Row>
          <Spacer height={4} />

          <Row>
            <Col sm={6}>
              <Datepicker name="dropOffDateFrom" value={filterForm.values.dropOffDateFrom} onChange={filterForm.handleChange} />
            </Col>
            <Col sm={6}>
              <Datepicker name="dropOffDateTo" value={filterForm.values.dropOffDateTo} onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Label>FSA/Postal</Label>
              <Spacer height={4} />
              <Input name="postal" type="text" placeholder="Enter FSA/Postal" onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Label>Show orders</Label>
              <Spacer height={4} />
              <Radio name="assigned" value={ASSIGNED_STATUS.ALL} label="All orders" defaultChecked onChange={filterForm.handleChange} />
              <Spacer height={4} />
              <Radio name="assigned" value={ASSIGNED_STATUS.ASSIGNED} label="Assigned" onChange={filterForm.handleChange} />
              <Spacer height={4} />
              <Radio name="assigned" value={ASSIGNED_STATUS.UNASSIGNED} label="Unassigned" onChange={filterForm.handleChange} />
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Label>Order status</Label>
              <Spacer height={5} />

              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.OTHER} label="Other" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.QUOTATION} label="Quotation" onChange={filterForm.handleChange} />
                </Col>
              </Row>
              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.PROCESSING} label="Processing" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.REJECTED} label="Rejected" onChange={filterForm.handleChange} />
                </Col>
              </Row>
              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.PENDING} label="Pending" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.ASSIGNED} label="Assigned" onChange={filterForm.handleChange} />
                </Col>
              </Row>
              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.TRANSIT} label="Transit" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.DELIVERED} label="Delivered" onChange={filterForm.handleChange} />
                </Col>
              </Row>
              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.DELAYED} label="Delayed" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.RETURNED} label="Returned" onChange={filterForm.handleChange} />
                </Col>
              </Row>

            {/*
              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.FAILED} label="Failed" onChange={filterForm.handleChange} />
                </Col>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.HOLD} label="Hold" onChange={filterForm.handleChange} />
                </Col>
              </Row>
             */}

              <Spacer height={4} />
              <Row>
                <Col>
                  <Checkbox name="status" value={ORDER_STATUS.CANCELLED} label="Cancelled" onChange={filterForm.handleChange} />
                </Col>
                {/* <Col>
                  <Checkbox name="status" value={ORDER_STATUS.CLEARING} label="Clearing" onChange={filterForm.handleChange} />
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Spacer height={20} />

          <Row>
            <Col sm={12}>
              <Button type={ControllerType.PRIMARY} block onClick={searchOrders} role="submit">Apply filters</Button>
            </Col>
          </Row>
          <Spacer height={10} />
          <Row>
            <Col sm={12}>
              <Button type={ControllerType.GHOST} block onClick={filterForm.resetForm} role="reset">Clear</Button>
            </Col>
          </Row>
        </form>

      </Styled.Sidebar>
      <Spacer width={24} />
      <Styled.Content>
        <Styled.TopBar>
          <Row align="center">
            <Col sm={3}>
              <Button size={ControllerSize.SMALL} onClick={() => setIsModalOpen(true)} >Create routes</Button>
            </Col>
            <Col sm={2}>
              <Checkbox name="selectAllPackages" label="Mark All" value={1} onChange={handleMarkAll} checked={allselected}/>
            </Col>
            <Col>
              <Input type="text" name="search" placeholder="Search" onChange={filterForm.handleChange} size={ControllerSize.SMALL} value={filterForm.values.search} onKeyDown={handleSearchKeyDown}/>
            </Col>
            <Col>
              <Select name="sort" onChange={filterForm.handleChange} value={filterForm.values.sort} size={ControllerSize.SMALL}>
                <option value={ORDERS_SORT_ORDER.pickUpAddressUp}>Pickup address (A-Z)</option>
                <option value={ORDERS_SORT_ORDER.pickUpAddressDown}>Pickup address (Z-A)</option>
                <option value={ORDERS_SORT_ORDER.pickUpPostalUp}>Pickup Postal Code (Ascending to descending)</option>
                <option value={ORDERS_SORT_ORDER.pickUpPostalDown}>PickupPostal Code (Descending to Ascending)</option>
                <option value={ORDERS_SORT_ORDER.dropOffAddressUp}>Delivery address (A-Z)</option>
                <option value={ORDERS_SORT_ORDER.dropOffAddressDown}>Delivery address (Z-A)</option>
                <option value={ORDERS_SORT_ORDER.dropOffPostalUp}>Delivery Postal Code (Ascending to descending)</option>
                <option value={ORDERS_SORT_ORDER.dropOffPostalDown}>Delivery Postal Code (Descending to Ascending)</option>
                <option value={ORDERS_SORT_ORDER.pickUpTimeUp}>Pickup time (Soonest to latest)</option>
                <option value={ORDERS_SORT_ORDER.pickUpTimeDown}>Pickup time (latest to soonest)</option>
                <option value={ORDERS_SORT_ORDER.dropOffTimeUp}>Dropoff Time (Soonest to latest)</option>
                <option value={ORDERS_SORT_ORDER.dropOffTimeDown}>Dropoff Time (latest to soonest)</option>
              </Select>
            </Col>
          </Row>
        </Styled.TopBar>
        <Spacer height={30} />
        {orders.length > 0 && !isOrdersLoading && orders.map(order => (
          <Order
            order={order}
            key={order.orderId}
            addPackage={addPackage}
            removePackage={removePackage}
            selectedPackages={selectedPackages}
          />
        ))}
        {orders.length === 0 && !isOrdersLoading && <Card>
          No orders found. Please adjust your search criteria
        </Card>}
        {isOrdersLoading && <Card>
          Fetching orders
        </Card>}
        {orders.length > 0 && !isOrdersLoading && pagination.totalPages > 0 && <>
          <Spacer height={20} />
          <Pagination totalPages={pagination.totalPages} page={pagination.page} onSelectPage={handlePageSelect} />
        </>}
      </Styled.Content>
      <Spacer width={24} />

      <Styled.Sidebar>
        <Typography type={TypographyType.titleLarge}>New route</Typography>
        <Spacer height={30} />

        <Label>Packages selected</Label>
        <Spacer height={4} />
        <Typography type={TypographyType.titleLarge}>{selectedPackages.length} pcs</Typography>
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Row>
          <Col sm={8}>
            <Label>Volume</Label>
            <Spacer height={4} />
            <Typography type={TypographyType.titleLarge}>{totalVolume}</Typography>
          </Col>
          <Col sm={4} ><CarImage car={carForVolume} /></Col>
        </Row>
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Label>Vehicle needed</Label>
        <Spacer height={4} />
        <Typography type={TypographyType.titleLarge}>{`${CAR_IMAGE[carForVolume][0].toUpperCase()}${CAR_IMAGE[carForVolume].slice(1)}`}</Typography>
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Label>Distance</Label>
        <Spacer height={4} />


        <Typography type={TypographyType.titleLarge}>{totalMiles} miles</Typography>
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Label>Time</Label>
        <Spacer height={4} />
        <Typography type={TypographyType.titleLarge}>{timeForDistance}</Typography>
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Label>Cost, USD</Label>
        <Spacer height={4} />
        <Input name="cost" type="text" placeholder="Enter cost" />
        <Spacer height={16} />
        <HR />
        <Spacer height={16} />

        <Label>Assign to</Label>
        <Spacer height={4} />
        <Select>
          <option>Steven King</option>
        </Select>
        <Spacer height={16} />
        <HR />
        <Spacer height={30} />

        <Button type={ControllerType.PRIMARY} block onClick={handleCreateRoute} disabled={selectedPackages.length === 0 || hasAssignedPackages}>Create route</Button>

      </Styled.Sidebar>
    </Styled.Container>
  )
}

export default OrderManagement

