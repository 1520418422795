
import Styled from "./Pagination.styled";

interface PaginationProps {
  page: number;
  totalPages: number;
  onSelectPage: (page: number) => any;
}

const PAGES_AVAILABLE = 5
const EXTRA_PAGES_OFFSET = 2

const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPages,
  onSelectPage
}) => {
  let pages = new Array(5).fill(1);
  const isNearToEnd = totalPages-page+EXTRA_PAGES_OFFSET <= PAGES_AVAILABLE
  const isNearToStart = page+EXTRA_PAGES_OFFSET <= PAGES_AVAILABLE;

  if(isNearToEnd) {
    pages = pages.map((p, index) => totalPages - index).reverse()
  }

  if(isNearToStart) {
    pages = pages.map((p, index) => index + 1)
  }

  if(!isNearToEnd && !isNearToStart) {
    pages = [
      page-2,
      page-1,
      page,
      page+1,
      page+2,
    ]
  }

  const handlePageClick = (_page: number) => () => {
    if(_page === page) {
      return;
    }

    onSelectPage(_page)
  }

  if(totalPages === 1) {
    return null
  }

  return (
  <Styled.Container>
    {(!isNearToStart || isNearToEnd) && <>
      <Styled.Page active={false} onClick={handlePageClick(1)}>1</Styled.Page>
      <Styled.Page active={false} stub>...</Styled.Page>

    </>}
    {pages.map(_page => (
      <Styled.Page key={_page} active={_page === page} onClick={handlePageClick(_page)}>{_page}</Styled.Page>
    ))}
    {(isNearToStart || !isNearToEnd) && <>
      <Styled.Page active={false} stub>...</Styled.Page>
      <Styled.Page active={false} onClick={handlePageClick(totalPages)}>{totalPages}</Styled.Page>

    </>}
    </Styled.Container>
  );
  }

export default Pagination;
