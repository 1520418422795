import styled from "styled-components";

interface SpacerProps {
  inline?: boolean;
  height?: number;
  width?: number;
}

export default styled.div<SpacerProps>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  height: ${(props) => (props.height ? `${props.height}px` : 0)};
  width: ${(props) => (props.width ? `${props.width}px` : 0)};
`;
