import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Presenter = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`

const Styled = {
  Presenter,
  Container
}

export default Styled;